<template>
  <van-search
    id="pickOverSearch"
    v-model="value"
    :placeholder="placeholder"
    :disabled="disabled"
    shape="round"
    ref="search"
    @focus="handleFocus"
    @blur="handleBlur"
    @input="handleInput"
    @keydown.enter="handleClick"
    @clear="handleClear"
    :maxlength="10"
  >
    <img
      src="/images/appMainView/search.png"
      slot="left-icon"
      class="searchIcon"
    />
  </van-search>
</template>

<script>
export default {
  components: {},
  props: {
    placeholder: String,
    disabled: {
      type: Boolean,
      default: () => false,
    },
    // 是否需要自动聚焦，默认聚焦
    isFocus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  beforeRouteEnter() {
    this.$refs.search.querySelector("input").focus();
  },
  mounted() {
    // 当前窗口得到焦点
    if (this.isFocus) {
      // document.getElementById("pickOverSearch").focus();
      this.$refs.search.querySelector("input").focus();
    }
  },
  methods: {
    // 自动聚焦
    setAutofocus(){
      this.$refs.search.querySelector("input").focus();
    },
    // 清除内容
    clearValue () {
      this.value = ''
    },
    handleCursor(element) {
      let range = document.createRange();
      range.selectNodeContents(element);
      range.collapse(false);
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    },
    // 获取焦点
    handleFocus(val) {
      this.$emit("focus", val);
    },
    // 失去焦点
    handleBlur(val) {
      this.$emit("blur", val);
    },
    // 获取键盘值
    handleInput(val) {
      this.$emit("input", val);
    },
    // 点击
    handleClick() {
      this.$emit("click", this.value);
    },
    // 清除
    handleClear() {
      this.$emit("clear");
    },
  },
};
</script>

<style lang="scss" scoped>
.van-search {
  height: 42px;

  .searchIcon {
    width: 16.5px;
    position: relative;
    top: 3px;
    margin-right: 2px;
  }

  .van-search__content {
    background-color: #f6f6f6;
    color: $remarkColor;
    padding-top: 1px;
    padding-bottom: 1px;

    ::v-deep input {
      font-size: 13px;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .van-field__control {
  -webkit-text-fill-color: #6d7278 !important;
}
</style>
