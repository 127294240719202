<template>
  <van-swipe class="swipeView" :autoplay="3000" indicator-color="white">
    <van-swipe-item class="swipe_item" v-for="(item,index) in options" :key="index" @click="handleClick(item)">
      <img class="banner-img" :src="item.imgUrl" />
    </van-swipe-item>
  </van-swipe>
</template>

<script>
import {isURL} from "@/utils/utils";

export default {
  props: {
    options: Array
  },
  mounted() {

  },
  methods: {
    goTO(url) {
      if (isURL(url)) {
        window.location.href = url
      }
    },
    handleClick(item) {
      this.$emit('click', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.swipeView {
  display: flex;
  margin-bottom: 14px;
  min-height: 120px;

  .banner-img {
    width: 100%;
    min-height: 120px;
    //object-fit: cover;
  }

  .swipe_item {
    width: 100%;
  }
}
</style>
