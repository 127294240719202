<template>
  <div class="mainView" ref="mainView" id="apply">
    <div class="head" ref="head">
      <img class="logo" src="/images/appMainView/logo.png" />
      <searchBox
        :placeholder="placeholder"
        @click.native="toSearch"
        ref="searchs"
        disabled
        class="mainView_search"
      />
    </div>

    <!-- 主体内容区域-->
    <!--    @scroll="scrollEvent"-->
    <div
      class="content"
      ref="content"
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="loadMore"
    >
      <!--  todo    下拉刷新-->
      <van-pull-refresh
        class="refresh"
        v-model="refreshing"
        @refresh="onRefresh"
      >
        <div class="mainView_head">
          <!-- todo 轮播图-->
          <swipe :options="swipeOptions" @click="handleSwiperClick" />
          <!-- todo 旧版本的热帖和活动-->
          <!-- <navCardList :options="navCardOptions" @click="handleNavCardClick"/>-->
          <NewNavCardList></NewNavCardList>
        </div>
        <!--todo  tabs 固定模块和首页推荐的圈子列表-->
        <van-sticky :offset-top="100">
          <Tabs
            style="border-top: 1px solid #f5f5f5; margin-top: -1px"
            ref="Tabs"
            @handleSort="handleSort"
            @changeTabs="handleChangeTabs"
          ></Tabs>
        </van-sticky>
        <!--        <Tabs-->
        <!--          ref="Tabs"-->
        <!--          :class="suckTheTop ? 'suck-theT-top' : ''"-->
        <!--          @handleSort="handleSort"-->
        <!--          @changeTabs="handleChangeTabs"-->
        <!--        ></Tabs>-->

        <!-- 按照甲方要求隐藏，不可删除 -->
        <!--      <div class="mainView_kingkong">
              <div class="kingkong" v-for="item in types" :key="item.value" @click="handleTypeChange(item)">
                <img :src="item.defaultImg" alt="" class="icon">
                <span class="label">{{item.label}}</span>
              </div>
            </div>-->
        <!-- 暂时无人发声，最新，最热-->
        <!--      <div class="mainView_bar">-->
        <!--        <div class="horse">-->
        <!--          <img class="horn" src="/images/appMainView/horn.png">-->
        <!--          <horse-lamp :data='newArticleList' />-->
        <!--        </div>-->
        <!--        <div class="filterBar">-->
        <!--          <div v-for="(item, index) in sortKeySelectOptions" :key="index" class="filterBar_lable"-->
        <!--            :class="item.value===sortKey?'active':''"-->
        <!--            @click="changeSort(item.value)">-->
        <!--            {{ item.label }}-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--  todo      官方推荐的圈子-->
        <div
          class="circleItem-container"
          v-if="
            JSON.stringify(circleItem) != '{}' &&
            currentTab != 1 &&
            currentTab != 2
          "
        >
          <CircleItem
            class="circleItem"
            :item="circleItem"
            :user="user"
            :isShowIntro="true"
          ></CircleItem>
        </div>

        <div
          class="mainView_content"
          :style="{ paddingBottom: topicData.length <= 5 ? '400px' : 0 }"
          ref="mainView"
        >
          <topic
            v-for="(item, index) in topicData"
            :id="'articleId' + item.articleId"
            :key="index"
            :data="item"
            :isList="true"
            class="topicItem"
            @comment="handleComment"
            @userInfoClick="handleToUserDetail(item.userBasicVO.userId)"
            @share="handleShare"
            @like="like($event, index)"
            @favorite="favorite($event, index)"
            @originalClick="handleTopicClick"
            @click="handleTopicClick"
            @tagClick="handleTagClick"
            @attClick="handleToUserDetail"
            @follow="handleFollow(item, index)"
            @handleNewActive="handleNewActive"
          />
          <!--          当不足10条数据时添加空白页面，防止tabs不吸顶-->
          <!--          <div v-if="topicData.length < 10">-->
          <!--            <div v-for="number in  (10 - topicData.length)" :key="number" style="height: 200px">-->
          <!--              <span v-show="false">{{ number }}</span>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </van-pull-refresh>
      <no-more v-if="!listQueryParams.hasNextPage" />
      <div class="addBtn">
        <img src="/images/appMainView/add.png" @click="handlerAdd" />
        <div class="wave wave0"></div>
      </div>
      <!-- 评论弹窗 -->
      <commentToolDialog
        :show-dialog.sync="showDialog"
        :isAnonymousComment.sync="isAnonymousComment"
        v-model="commentContent"
        hide-img
        :iosMarginBottom="iosMarginBottom"
        @publish="handlePublishComment"
        :attList="attList"
        @delete="handleDeleteAtt"
      />
    </div>
  </div>
</template>

<script>
import { getItem, removeItem } from '@/utils/storage'
import { mapGetters } from 'vuex'
import Tabs from './components/tabs/index'
import CircleItem from '@/components/circle/circleItem'
import NewNavCardList from './components/navCardList/newIndex'
import swipe from './components/swipe'
// import horseLamp from './components/horseLamp'
import topic from '@/components/topicItem'
import searchBox from '@/components/searchBox'
import NoMore from '@/components/uitls/NoMore'
import commentToolDialog from '@/components/commentToolDialog'

import { getPosterPage } from '@/api/postermanagement'
import {
  getByPage,
  getMeIntrant,
  getCircleTopicList
  // detail as getArticleDetail
  // articleHotCount,
  // activityCount,
  // newArticle
} from '@/api/article'
import { addThumbsup, removeThumbsup } from '@/api/thumbsup'
import { addFavorites, removeFavorites } from '@/api/favorites'
import { addComment } from '@/api/comment'
import { clearJumpData, getJumpData } from '@/utils/tempLoginData'
import { attAdd, followRemove } from '@/api/att'
import { isAndroid } from '@/utils/plamtom'
import { debounce } from '@/utils/debounce'

const ENTITY_TYPE_ARTICLE = 2
let timer

export default {
  components: {
    Tabs,
    NewNavCardList,
    swipe,
    // horseLamp,
    topic,
    searchBox,
    NoMore,
    commentToolDialog,
    CircleItem
  },
  data() {
    const iconBasePath = '/images/appMainView'
    // ios设备需要添加margin
    let ios = !isAndroid()
    return {
      articleIdList: [], // 帖子的id列表
      iosMarginBottom: ios,
      navCardOptions: [
        {
          label: '热榜推荐',
          routeName: 'popular',
          iconUrl: `${iconBasePath}/popular.png`,
          smallIcon: `${iconBasePath}/hot.png`,
          tags: [{ text: '最近7天' }, { text: '0', active: true, hidden: true }]
        },
        {
          label: '社区活动',
          routeName: 'activity',
          iconUrl: `${iconBasePath}/activity.png`,
          tags: [{ text: '正在进行' }, { text: '0个', active: true }]
        }
      ],
      swipeOptions: [1, 2, 4],
      currentTab: 2,
      topicData: [],
      placeholder: '搜索帖子/圈子/员工', // 即刻搜索，即刻呈现
      showDialog: false,
      attList: [],
      commentParams: {},
      commentContent: '',
      isAnonymousComment: false,
      listQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
        categoryId: '1',
        sortType: '1'
      },
      sortKey: 'time',
      sortKeySelectOptions: [
        { label: '发布时间', value: 'time' },
        { label: '浏览人数', value: 'like' }
      ],
      newArticleList: [],
      focus: false,
      types: [
        {
          label: '向往的生活',
          value: '1',
          chosen: true,
          defaultImg: require('@/assets/imgs/main/operate-active.png'),
          chosenImg: require('@/assets/imgs/main/operate-active.png'),
          originImg: require('@/assets/imgs/main/operate.png')
        },
        {
          label: '吐槽在这里',
          value: '2',
          chosen: false,
          defaultImg: require('@/assets/imgs/main/talk.png'),
          chosenImg: require('@/assets/imgs/main/talk-active.png'),
          originImg: require('@/assets/imgs/main/talk.png')
        },
        {
          label: '热共创专区',
          value: '3',
          chosen: false,
          defaultImg: require('@/assets/imgs/main/topic.png'),
          chosenImg: require('@/assets/imgs/main/topic-active.png'),
          originImg: require('@/assets/imgs/main/topic.png')
        },
        {
          label: '大神帮帮忙',
          value: '4',
          chosen: false,
          defaultImg: require('@/assets/imgs/main/help.png'),
          chosenImg: require('@/assets/imgs/main/help-active.png'),
          originImg: require('@/assets/imgs/main/help.png')
        }
      ],
      circleItem: {},
      topValue: null,
      suckTheTop: false,
      refreshing: true,
      isShare: false
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  created() {
    // activityCount().then((res) => {
    //   this.navCardOptions[1].tags[1].text = `${res}个`
    // }),
    // articleHotCount().then((res) => {
    //   this.navCardOptions[0].tags[1].text = res
    // })
    this.initHomeData()
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      document.title = '不氪套社区'
      if (from.name === 'organization') {
        // 从@页面回来重新渲染@列表
        if (vm.$route.query.selectMembers) {
          vm.attList = vm.$route.query.selectMembers
            .split(',')
            .filter((item) => item)
            .map((item) => {
              const [attId, attName] = item.split(':')
              return { attId, attName }
            })
        }
        vm.commentParams =
          localStorage.getItem('commentParams') &&
          JSON.parse(localStorage.getItem('commentParams'))
      } else if (
        (from.name === 'createTopic' || from.name === 'topicCategory') &&
        localStorage.getItem('refreshFlag')
      ) {
        // 发表完帖子刷新首页
        vm.topicData = []
        vm.listQueryParams = {
          pageNum: 1,
          pageSize: 10,
          hasNextPage: true,
          categoryId: '1',
          sortType: '1'
        }
        vm.$refs.Tabs.isSortNew = true
        vm.$refs.Tabs.tabsActiveIndex = 2
        vm.currentTab = 2
        vm.loadList()
        vm.horselampList()
        removeItem('refreshFlag')
      } else if (from.name === 'topicDetail') {
        // 分享进入详情，返回到首页
        if (vm.isShare) {
          vm.isShare = false
          window.location.reload()
        } else {
          // 帖子详情页删除帖子需要刷新页面
          const refreshFlag = getItem('refreshFlag')
          if (refreshFlag === 'true' || refreshFlag) {
            vm.onRefresh()
            removeItem('refreshFlag')
          } else {
            // 帖子详情页面返回回显帖子数据
            const { id, thumbsUpFlag, relationVO, articleDetail } =
              getItem('refreshData')
            vm.topicData = vm.topicData.map((item) => {
              console.log(id)
              console.log(item)
              if (item.articleId === id) {
                item = articleDetail
                item.thumbsUpFlag = thumbsUpFlag
                item.relationVO = relationVO
                console.log(articleDetail)
                return articleDetail
              } else {
                return item
              }
            })
            vm.$forceUpdate()
          }
        }
      } else if (from.name === 'circleMain') {
        vm.topicData = []
        if (localStorage.getItem('recordEchoId') !== 'undefined') {
          vm.listQueryParams = {
            pageNum: 1,
            pageSize: 10,
            hasNextPage: true,
            categoryId: '1',
            sortType: '1',
            circleId: JSON.parse(localStorage.getItem('recordEchoId'))
          }
        } else {
          vm.listQueryParams = {
            pageNum: 1,
            pageSize: 10,
            hasNextPage: true,
            categoryId: '1',
            sortType: '1'
          }
        }
        vm.loadList()
        vm.horselampList()
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    localStorage.setItem('recordEchoId', JSON.stringify(this.circleItem.id))
    if (to.name === 'organization') {
      localStorage.setItem('commentParams', JSON.stringify(this.commentParams))
    }
    localStorage.setItem(
      'scrollTop',
      JSON.stringify(this.$refs.content.scrollTop)
    )
    next()
  },
  mounted() {
    // this.testShareWechat();
    // this.testSanbuShare();
    // let height = this.$refs.head.offsetHeight
    // let searchHeight = this.$refs.searchs.$el.offsetHeight
    this.topValue = this.getOffsetTop(this.$refs.Tabs.$el)
    console.log(this.topValue)
    setTimeout(() => {
      this.checkJumpAPP()
      this.checkJumpShare()
    }, 100)
  },
  activated() {
    document.title = '不氪套社区'
    // timer = setInterval(() => {
    //   this.horselampList()
    // }, 1000 * 60)
    this.$refs.content.scrollTop =
      localStorage.getItem('scrollTop') &&
      JSON.parse(localStorage.getItem('scrollTop'))
  },
  deactivated() {
    clearInterval(timer)
  },
  methods: {
    // 页面初始化
    initHomeData() {
      this.getPosterPage()
      this.horselampList()
      this.loadList()
    },
    /**
     * 轮播图列表
     */
    getPosterPage() {
      getPosterPage().then((res) => {
        this.swipeOptions = res.records
      })
    },
    /**
     * 下拉刷新
     */
    onRefresh() {
      let circleId = this.currentTab
      this.$refs.Tabs.isSortNew = true
      this.$refs.Tabs.tabsActiveIndex = circleId
      const res = this.$refs.Tabs.getTabsList()
      res.then((list) => {
        const idList = list.filter((item) => item.id === circleId) || []
        if (idList.length === 0) {
          circleId = 2
          this.currentTab = 2
          this.$refs.Tabs.tabsActiveIndex = 2
        }
        this.listQueryParams = {
          pageNum: 1,
          pageSize: 10,
          hasNextPage: true,
          categoryId: '1',
          sortType: '1',
          circleId
        }
        this.topicData = []
        this.loadMore()
      })
    },
    // 获取偏移量
    getOffsetTop(obj) {
      let tmp = obj.offsetTop
      let node = obj.offsetParent
      while (node != null) {
        tmp += node.offsetTop
        node = node.offsetParent
      }
      return tmp
    },
    // scrollEvent () {
    //   if (this.topValue <= this.$refs.content.scrollTop) {
    //     this.suckTheTop = true
    //   } else {
    //     this.suckTheTop = false
    //   }
    // },

    // 切换最新，最热
    handleSort(type) {
      let circleId = this.circleItem.id
      this.listQueryParams = {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
        categoryId: '1',
        circleId
      }
      this.listQueryParams.sortType = type ? '1' : '2'
      // this.topicData = []
      this.loadMore(true)
    },
    handleChangeTabs(type) {
      if (type.id != 1 && type.id != 2) {
        this.circleItem = type
      } else {
        this.circleItem = {}
      }
      this.currentTab = type.id
      let circleId = type.id
      this.listQueryParams = {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
        categoryId: '1',
        circleId
      }
      this.listQueryParams.sortType = type ? '1' : '2'
      // this.topicData = []
      this.loadMore(true)
    },
    toSearch() {
      document.getElementById('pickOverSearch').focus()
      this.$router.push({
        name: 'search',
        query: {
          placeholder: this.placeholder,
          type: 'post'
        }
      })
      document.getElementById('pickOverSearch').focus()
    },
    handleNewActive(item) {
      const index = this.topicData.findIndex((i) => {
        return i.articleId === item.articleId
      })
      this.topicData[index].articleVoteVO = item
    },
    /**
     * 轮播图跳转
     */
    handleSwiperClick(data) {
      const type = data.type
      if (type === 0 || type === 1) {
        this.$router.push({
          name: 'topicDetail',
          query: { id: data.articleId }
        })
      } else if (type === 2) {
        this.$router.push({ name: 'forwardToWechat', query: { id: data.id } })
      } else if (type === 3) {
        window.location.href = data.content
      }
    },
    loadMore(reset = false) {
      this.loadList(reset)
    },
    handleNavCardClick({ routeName: name } = {}) {
      this.$router.push({ name })
    },
    handlerAdd() {
      this.$router.push({ name: 'createTopic' })
    },
    changeSort(value) {
      this.sortKey = value
      this.topicData = []
      this.listQueryParams = { pageNum: 1, pageSize: 10, hasNextPage: true }
      if (this.sortKey === 'like') {
        this.listQueryParams.sortType = 1
      }
      this.loadList()
    },
    /**
     * 帖子列表
     */
    handleTopicClick({ articleId } = {}) {
      this.$router.push({ name: 'topicDetail', query: { id: articleId } })
    },
    handleTagClick(tag) {
      console.log(tag)
      this.$router.push({ name: 'searchTag', query: { tag: tag } })
    },
    // 获取广场的帖子列表
    async getListSquare(reset = false) {
      let listQueryParams = JSON.parse(JSON.stringify(this.listQueryParams))
      try {
        const res = (await getByPage(this.listQueryParams)) || {}
        listQueryParams.hasNextPage = res.current < res.pages
        listQueryParams.pageNum = listQueryParams.pageNum + 1
        this.listQueryParams = listQueryParams
        let resData = reset ? [] : this.topicData
        console.log(res)
        let { records } = res || []
        this.articleIdList = []
        records.forEach((item) => {
          let count = resData.filter(
            (innerItem) => innerItem.articleId === item.articleId
          )
          if (count.length === 0) {
            resData.push(item)
          }
          this.articleIdList.push(item.articleId)
        })
        if (reset) {
          this.topicData = JSON.parse(JSON.stringify(resData))
        } else {
          this.topicData = resData
        }
        await this.getArticleDetail()
      } finally {
        this.refreshing = false
      }
    },
    /**
     * 获取帖子详情
     */
    async getArticleDetail() {
      const publishNewArticleDetail = getItem('publishNewArticleDetail')
      if (publishNewArticleDetail) {
        const { articleId, fromRouter } = publishNewArticleDetail
        if (fromRouter === 'home') {
          if (this.articleIdList.indexOf(articleId) !== -1) {
            this.$nextTick(() => {
              // 找到需要锚点的元素
              let element = document.getElementById(`articleId${articleId}`)
              // console.log(element.offsetTop)
              // 父容器

              // let elementContent = document.getElementsByClassName('refresh van-pull-refresh')
              // elementContent.scrollTop = (element.offsetTop - 100)
              if (element) {
                // 平滑的跳转
                // 浏览器支持的友好，但缺少center选项
                element.scrollIntoView(true)
                // 传参数模式下 浏览区支持的不是太友好
                // element.scrollIntoView({
                //   behavior: 'smooth',
                //   block: 'center',
                // });
              }
              removeItem('publishNewArticleDetail')
            })
          } else {
            // console.log(this.articleIdList)
            if (this.listQueryParams.pageNum < 6) {
              this.loadList()
            }
          }
          // // 获取帖子详情
          // this.articleDetail = await getArticleDetail({articleId})
          // if(this.articleDetail && Object.keys(this.articleDetail).length > 0){
          //   this.topicData.unshift(this.articleDetail)
          // }
          // //平滑的跳转
          // let element = document.getElementById(`articleId${articleId}`);
          // if (element) {
          //   element.scrollIntoView({ behavior: 'smooth' });
          // }
        }
      }
    },
    // 加载更多数据
    loadList(reset = false) {
      let listQueryParams = this.listQueryParams
      listQueryParams.isVote = 0
      if (listQueryParams.hasNextPage) {
        if (this.currentTab === 2) {
          // console.log('publishNewArticleDetail')
          // console.log(publishNewArticleDetail)
          this.getListSquare(reset)
        } else if (this.currentTab === 1) {
          // 查询我加入圈子的帖子列表
          getMeIntrant(this.listQueryParams)
            .then((res) => {
              listQueryParams.hasNextPage = res.current < res.pages
              listQueryParams.pageNum = listQueryParams.pageNum + 1
              let resData = reset ? [] : this.topicData
              res.records.forEach((item) => {
                let count = resData.filter(
                  (innerItem) => innerItem.articleId === item.articleId
                )
                if (count.length === 0) {
                  resData.push(item)
                }
              })
              this.topicData = resData
            })
            .finally(() => {
              this.refreshing = false
            })
        } else {
          // 查询圈子帖子列表
          getCircleTopicList(this.listQueryParams)
            .then((res) => {
              listQueryParams.hasNextPage = res.current < res.pages
              listQueryParams.pageNum = listQueryParams.pageNum + 1
              let resData = reset ? [] : this.topicData
              res.records.forEach((item) => {
                let count = resData.filter(
                  (innerItem) => innerItem.articleId === item.articleId
                )
                if (count.length === 0) {
                  resData.push(item)
                }
              })
              this.topicData = resData
            })
            .finally(() => {
              this.refreshing = false
            })
        }
      }
    },
    like: debounce(function (res, index) {
      this.topicData[index].isThumbsUp = !res.isThumbsUp
      // 取消点赞
      if (res.isThumbsUp) {
        let data = this.topicData[index]
        let count = data.articleStatisticVO.thumbsUpCount - 1
        if (count < 0) count = 0
        data.articleStatisticVO.thumbsUpCount = count
        removeThumbsup({
          entityId: res.articleId,
          entityType: 1
          // eslint-disable-next-line no-unused-vars
        })
      } else {
        // 添加点赞
        let data = this.topicData[index]
        // if (!data.articleStatisticVO) {
        //   data.articleStatisticVO = {}
        // }
        data.articleStatisticVO.thumbsUpCount =
          data.articleStatisticVO.thumbsUpCount + 1
        addThumbsup({
          entityId: res.articleId,
          entityType: 1
        })
      }
    }, 200),
    favorite: debounce(function (res, index) {
      this.topicData[index].isFavorites = !res.isFavorites
      // 取消收藏
      if (res.isFavorites) {
        let data = this.topicData[index]
        let count = data.articleStatisticVO.favoritesCount - 1
        if (count < 0) count = 0
        data.articleStatisticVO.favoritesCount = count
        removeFavorites({
          entityId: res.articleId,
          entityType: '1'
        })
      } else {
        let data = this.topicData[index]
        // if (!data.articleStatisticVO) {
        //   data.articleStatisticVO = {}
        // }
        data.articleStatisticVO.favoritesCount =
          data.articleStatisticVO.favoritesCount + 1
        // 添加收藏
        addFavorites({
          entityId: res.articleId,
          entityType: '1'
        })
      }
    }, 200),
    /**
     * 分享事件
     */
    handleShare({ id: quoteId } = {}) {
      this.$router.push({ name: 'createTopic', query: { quoteId } })
    },
    /**
     * 跳转用户详情页
     */
    handleToUserDetail(userId) {
      this.$router.push({ name: 'userInfoDetail', params: { userId } })
    },
    async handleFollow(data) {
      const { id, myFollow } = data
      if (myFollow) {
        await followRemove(id)
        data.commonFollowCount--
      } else {
        await attAdd(id)
        data.commonFollowCount++
      }
      data.myFollow = !myFollow
    },
    /**
     * 添加评论
     * @param item
     */
    handleComment(item) {
      // this.showDcommentItemialog = true
      // this.commentParams = item
      // 根据甲方要求， 打开详情页回复消息
      this.$router.push({
        name: 'topicDetail',
        query: {
          id: item.articleId,
          showComponent: true,
          entityType: 2
        }
      })
    },
    /**
     * 发布评论
     */
    handlePublishComment: debounce(async function () {
      const {
        commentContent: content,
        commentParams: { articleId: entityId },
        isAnonymousComment: isAnonymous,
        attList
      } = this
      const entityType = ENTITY_TYPE_ARTICLE
      if (!content.trim()) {
        this.$notify({ message: '评论内容不能为空', type: 'danger' })
        return
      }
      const atUserIds = attList.map((item) => {
        return item.attId
      })
      await addComment({
        content,
        entityId,
        entityType,
        isAnonymous,
        atUserIds
      })
      this.attList = []
      this.showDialog = false
      this.commentParams = {}
      this.commentContent = ''
      this.topicData = this.topicData.map((item) => {
        if (item.articleId !== entityId) {
          return item
        }
        const { articleStatisticVO } = item
        return {
          ...item,
          articleStatisticVO: {
            ...articleStatisticVO,
            commentCount: articleStatisticVO.commentCount + 1
          }
        }
      })
    }, 200),
    handleDeleteAtt(index) {
      this.attList.splice(index, 1)
    },
    horselampList() {
      // newArticle().then((res) => {
      //   this.newArticleList = res
      // })
    },
    checkJumpAPP() {
      let data = getJumpData()
      if (data && data.jumpType === 'artice') {
        this.handleTopicClick({ articleId: data.articleId })
        clearJumpData()
      }
    },
    checkJumpShare() {
      let href = window.localStorage.getItem('wechatSharePage')
      if (href) {
        // 清除缓存
        window.localStorage.removeItem('wechatSharePage')
        this.isShare = true
        setTimeout(() => {
          let newHref = href.replace(
            `${window.location.protocol}//${window.location.host}`,
            ''
          )
          this.$router.push(newHref)
          // window.location.href = href
        })
      }
    },
    handleTypeChange(item) {
      if (item.chosen) return
      this.listQueryParams = {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
        categoryId: item.value
      }
      this.topicData = []
      this.loadList()
      this.types.forEach((it) => {
        if (it.value === item.value) {
          it.chosen = true
          it.defaultImg = it.chosenImg
        } else {
          it.chosen = false
          it.defaultImg = it.originImg
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.refresh {
  overflow: visible !important;
  height: 100% !important;
}

.focusState {
  position: absolute;
}

::v-deep {
  .mainView_head {
    padding-top: 0px !important;
  }

  .circleItem-container {
    margin: 0 !important;
    padding: 0 0 12px 0 !important;
    //height: 75px;
    background: #ffffff;

    .circle-item {
      margin: auto;
    }
  }
}

.mainView {
  overflow-y: auto;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 20;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    background-color: white;
    padding: 10px 0 0 0;

    .logo {
      height: 30px;
      margin-right: 10px;
    }

    .mainView_search {
      width: 100%;
      height: 60px !important;
      background-color: #fff;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .text {
      .title {
        font-size: 14px;
      }

      .subtitle {
        font-size: 8px;
        display: flex;
        justify-content: space-between;
      }

      p {
        margin: 0;
      }
    }
  }

  &_kingkong {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;
    background: #fff;
    margin: 0 10px 8px;
    border-radius: 6px;

    .kingkong {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        width: 40px;
        height: 40px;
        margin-bottom: 2px;
      }

      .label {
        font-size: 12px;
        color: $titleColor;
      }
    }
  }

  &_bar {
    display: flex;
    background-color: white;
    padding: 15px 15px 0;
    margin: 5px 10px 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .horse {
      display: flex;
      align-items: center;
      flex: 1;
      border-bottom: 1px solid #f4f4f4;
      padding-bottom: 15px;

      .horn {
        width: 18px;
        margin-right: 3px;
      }
    }

    .filterBar {
      font-size: 10px;
      color: $mainTextColor;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      border-bottom: 1px solid #f4f4f4;
      padding-bottom: 15px;

      &_lable {
        padding: 4px 5px;
        border: 1px solid $borderColor;

        &:first-child {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
          border-right: none;
        }

        &:last-child {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          border-left: none;
        }
      }

      .active {
        color: $mainColor;
        border: 1px solid $mainColor;
      }
    }
  }

  .content {
    flex: 1;
    overflow-y: auto;
  }

  &_head {
    background: white;
    padding: 8px 15px 16px;
    margin-top: 4px;
    margin-bottom: 5px;
  }

  &_content {
    margin-top: 5px;
    position: relative;
    background-color: #ffffff;

    .topicItem {
      //margin-bottom: 5px;

      &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .addBtn {
    position: fixed;
    width: 80px;
    bottom: 80px;
    right: 20px;
    z-index: 1001;

    img {
      width: 55px;
      z-index: 20;
      position: relative;
      left: 12px;
      top: 3px;
    }

    .wave {
      position: absolute;
      top: calc((100% - 30px) / 2);
      left: calc((100% - 30px) / 2);
      width: 30px;
      height: 30px;
      border-radius: 300px;
    }

    .wave0 {
      z-index: 2;
      background-size: auto 106%;
      animation: w 2s forwards infinite;
    }

    @keyframes w {
      0% {
        background: rgba(255, 69, 51, 0.5);
        top: calc((100% - 30px) / 2);
        left: calc((100% - 30px) / 2);
        width: 30px;
        height: 30px;
      }
      100% {
        background: transparent;
        top: calc((100% - 120px) / 2);
        left: calc((100% - 120px) / 2);
        width: 120px;
        height: 120px;
      }
    }
  }

  .suck-theT-top {
    position: fixed;
    top: 100px;
    left: 0;
    z-index: 10;
    right: 0;
  }
}
</style>
