<template>
  <div class="circle-item">
    <div class="flex-row-h-center left" @click="goToDetail(item)">
      <img class="circle-avatar" :src="item.avatar" alt="" />
      <div class="message" :class="!item.jkinfo ? 'mes1' : 'mes2'">
        <div class="flex-row-h-center">
          <span class="text-inline-ellipsis message_til" v-html="item.title"></span>
          <van-tag size="medium" v-if="!item.isPublic" color="#FE5800" plain type="primary">私密</van-tag>
        </div>

        <div
          class="two-line subTitle"
          v-if="isShowIntro"
          v-html="item.introduction"
        ></div>
        <div v-if="item.circleStatisticVO" class="message_gfzs">
          <span>{{ getUnitNumber(item, "memberCount") }}人加入</span>
          <span>&nbsp;|&nbsp;</span>
          <span>{{ getUnitNumber(item, "articleCount") || 0 }}帖子</span>
        </div>
      </div>
    </div>
    <div v-if="!isHiddenRight">
      <!--todo  我管理的圈子 -->
      <template v-if="isComeManage">
        <div
          v-if="item.identity === 1 || item.identity === 2"
          class="right_my"
          @click.stop=""
        >
          <template v-if="item.identity === 1">
            <div class="icon_box">
              <img class="icon_ri" :src="user.avatar" alt="" />
              <span class="icon_tit">我是圈主</span>
            </div>
          </template>
          <span class="icon_lis" @click.prevent="myGlCircle(item)"></span>
        </div>

        <template v-else>
          <div
            v-if="item.identity === 100"
            class="right join"
            style=""
            @click.stop="joinBtn(item)"
          >
            <van-button icon="plus" type="primary">按钮</van-button>
            <van-icon name="plus" />
            <span v-if="item.isJoinApply"> 申请</span>
            <span v-else> 加入</span>
          </div>
          <div class="right mine" v-else-if="item.identity === 1">我创建的</div>
          <div
            @click.stop="handleQuitCircle(item)"
            class="right have-join"
            style="background-color: #f6f6f6"
            v-else
          >
            <span>已加入</span>
          </div>
        </template>
      </template>
      <!--todo   通用的-->
      <div class="flex-row-h-center" v-else>
        <van-button
          v-if="item.identity === 100"
          @click="joinBtn(item)"
          color="#fe5800"
          round
          size="mini"
          icon="plus"
          type="primary"
        >
          <span v-if="item.isJoinApply">申请</span>
          <span v-else>加入</span>
        </van-button>
        <div v-else-if="item.identity === 1" class="right mine">我创建的</div>
        <van-button
          v-else
          @click="handleQuitCircle(item)"
          color="#f6f6f6"
          round
          size="mini"
        >
          <span style="color: #1a1a1a">已加入</span>
        </van-button>
        <!--      <div v-if="item.identity === 1000"-->
        <!--        class="flex-row-h-center right join"-->
        <!--        @click.stop="joinBtn(item)">-->
        <!--        <van-button icon="plus" type="primary">按钮</van-button>-->
        <!--&lt;!&ndash;        <span class="sign">+</span>&ndash;&gt;-->
        <!--&lt;!&ndash;        <span v-if="item.isJoinApply"> 申请</span>&ndash;&gt;-->
        <!--&lt;!&ndash;        <span v-else> 加入</span>&ndash;&gt;-->
        <!--      </div>-->
        <!--      <div @click.stop="handleQuitCircle(item)" class="right have-join" style="background-color: #f6f6f6;" v-else>-->
        <!--        <span>已加入</span>-->
        <!--      </div>-->
      </div>
    </div>
    <!--todo  加入原因-->
    <van-dialog
      v-model="isShowApply"
      title="申请原因"
      @confirm="handleConfirmApply"
      :show-cancel-button="true"
      get-container="#app">
      <van-cell-group slot="default">
        <van-field
          ref="applyReason"
          v-model="applyReason"
          label-width="60px"
          label="原因"
          show-word-limit
          maxlength="30"
          :formatter="vanFieldFormatter"
          placeholder="请输入申请原因"
        />
      </van-cell-group>
    </van-dialog>
    <van-dialog
      v-model="isShowQuit"
      title="退出圈子"
      :confirmButtonText="'退出圈子'"
      :cancelButtonText="'再考虑下'"
      @confirm="handleConfirmQuit"
      :show-cancel-button="true"
      get-container="#app"
    >
      <div class="flex-center-row default-quit" slot="default">
        <span>{{ quitTips }}</span>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {vanFieldFormatter} from '@/utils/utils'
import {setApplyJoin, setQuitCircle} from "@/api/circle";

export default {
  name: "circleItem",
  props: {
    // 是否隐藏右边的按钮
    isHiddenRight: {
      type: Boolean,
      default: false,
    },
    // 是否显示简介
    isShowIntro: {
      type: Boolean,
      default: false,
    },
    // 是否来自我的管理
    isComeManage: {
      type: Boolean,
      default: false,
    },
    // 每一项数据
    item: {
      type: Object,
      default: () => {
      },
    },
    // 当前用户信息
    user: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      quitTips: "你确定要退出该圈子吗？",
      isShowQuit: false, // 退出圈子
      isShowApply: false, // 需要申请原因
      applyReason: "", // 申请加入原因
    };
  },
  methods: {
    vanFieldFormatter,
    // 超过99显示99+，后期可能改为万，亿
    getUnitNumber(item, key) {
      if (!item.circleStatisticVO) {
        return;
      }
      let number = item.circleStatisticVO[key];

      if (typeof number === "string") {
        number = parseInt(number);
      }
      if (number > 99) {
        return "99+";
      } else {
        return number + "";
      }
    },
    // 确认
    handleConfirmApply() {
      this.applyReason = this.applyReason.trim();
      if (this.applyReason) {
        this.setApplyJoin();
      } else {
        this.$toast({
          message: "请输入申请原因",
          position: 'top'
        });
      }
    },
    // 圈子详情
    goToDetail(item) {
      this.$router.push({
        path: "/app/circle/circleXq",
        query: {
          id: item.id,
          refreshCircle: true,
        },
      });
    },

    myGlCircle(item) {
      this.$emit("myGlCircle", item);
    },

    /**
     * 直接加入圈子 不需要申请
     */
    handleJoinBtn() {
      this.setApplyJoin();
    },
    /**
     * 需要申请加入
     */
    handleJoinAppleBtn() {
      this.applyReason = "";
      this.isShowApply = true;
      this.$nextTick(()=>{
        setTimeout(()=>{
          // document.getElementById('applyReason').focus();
          this.$refs.applyReason.focus()
          // document.getElementById('applyReason').focus();
        },500)
      })
    },

    /**
     * 申请加入圈子接口
     * @returns {Promise<void>}
     */
    async setApplyJoin() {
      try {
        const data = {
          applyReason: this.applyReason,
          circleId: this.item.id,
        };
        const res = await setApplyJoin(data);
        if (this.item.isJoinApply) {
          this.$toast({
            message: "申请提交成功",
            position: 'top'
          });
        } else {
          this.$toast({
            message: "加入成功",
            position: 'top'
          });
          this.item.identity = 3;
          this.$emit("joinCircle", res);
        }
      } catch (e) {
        const msg = e.response.data.msg;
        this.$toast({
          message: msg,
          position: 'top'
        });
      }
    },

    /**
     * 加入圈子按钮
     */
    joinBtn(item) {

      if (item.isJoinApply) {
        this.handleJoinAppleBtn();
      } else {
        this.handleJoinBtn();
      }
    },
    /**
     * 退出圈子
     */
    handleQuitCircle(item) {
      if(item.identity === 2){
        this.quitTips = '你确定要退出该圈子吗？退出后你将失去管理员权限'
      }else if (!item.isPublic) {
        this.quitTips = "你确定要退出该私密圈子吗？退出后再加入需要圈主邀请";
      }
      this.isShowQuit = true;
    },
    /**
     * 确认退出圈子
     */
    handleConfirmQuit() {
      this.setQuitCircle();
    },
    /**
     * 退出圈子接口
     */
    async setQuitCircle() {
      await setQuitCircle(this.item.id);
      this.$emit("quitCircle");
      this.item.identity = 100;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-button--mini {
    width: 64px;
    height: 28px;
  }
}

::v-deep .van-dialog__content {
  .default-quit {
    text-align: center;
    font-family: "PingFang SC";
    font-size: 14px;
    line-height: 23px;
    color: #222222;
    padding: 12px;
  }
}

.circle-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 24px;

  .left {
    .message_til {
      max-width: 120px;
      font-size: 14px;
      color: #000000;
      margin-right: 6px;
    }

    //.mes1 {
    //  position: relative;
    //  top: -5px;
    //}
    //
    //.mes2 {
    //  position: relative;
    //  top: -9px;
    //}

    .message {
      display: flex;
      flex-direction: column;

      .subTitle {
        margin-top: 2px;
        font-size: 12px;
        color: #6d7278;
        letter-spacing: 0;
        margin-right: 20px;
        word-break: break-all;
      }
    }

    .message_gfzs {
      margin-top: 2px;
      font-size: 12px;
      color: #6d7278;
    }

    .circle-avatar {
      width: 44px;
      height: 44px;
      min-width: 44px;
      min-height: 44px;
      margin-right: 6px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 28px;
    line-height: 28px;
    border-radius: 14px;
    font-size: 13px;

    .sign {
      font-size: 18px;
    }
  }

  .right.join {
    color: #ffffff;
    background-color: $mainColor;
  }

  .right.mine {
    color: $moreColor;
  }

  .right.have-join {
    color: #6d7278;
  }

  .right_my {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon_box {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon_ri {
        width: 16px;
        height: 16px;
        display: inline-block;
        background: #f60;
        border-radius: 50%;
      }

      .icon_tit {
        margin: 0 8px 0 4px;
        font-size: 12px;
        color: #fe5800;
      }
    }

    .icon_lis {
      width: 16px;
      height: 16px;
      display: inline-block;
      background: url("../../assets/imgs/circle/Slice41@2x.png") no-repeat;
      background-size: cover;
    }
  }
}
</style>
