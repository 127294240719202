

// 闪布login
import request from "@/utils/request";

export function getPosterPage() {
    return request({
        // url: '/api/zeekr-article/bbs/postermanagement/page',
        url: '/api/zeekr-article-new/posterManage/page',
        method: 'get',
        params: {pageNum:1, pageSize:10}
    })
}

export function addPosterForwardConut(id) {
    return request({
        url: '/api/zeekr-article/bbs/postermanagement/add-forward-count',
        method: 'get',
        params: {id: id}
    })
}

export function getPosterDetail(id) {
    return request({
        // url: '/api/zeekr-article/bbs/postermanagement/detail',
        url: '/api/zeekr-article-new/posterManage/detail',
        method: 'get',
        params: {id: id}
    })
}