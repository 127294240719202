import request from '@/utils/request'

/**
 *
 * @param articleId
 * @returns {*}
 */
export function attAdd(articleId) {
  return request({
    url: '/api/zeekr-article/att/add',
    method: 'get',
    params: {
      articleId
    }
  })
}

/**
 *
 * @param articleId
 * @returns {*}
 */
export function followRemove(articleId) {
  return request({
    url: '/api/zeekr-article/att/remove',
    method: 'get',
    params: {
      articleId
    }
  })
}

/**
 *
 * @param pageNum
 * @param pageSize
 * @returns {*}
 */
export function myFollow(params) {
  return request({
    url: '/api/zeekr-article/att/my-follow',
    method: 'get',
    params
  })
}