// 跳转存放临时数据
let key = "temp_data";
export function setJumpData(query){
    sessionStorage.setItem(key, JSON.stringify(query))
}

export function clearJumpData(){
    sessionStorage.removeItem(key)
}

export function getJumpData(){
    let temp = sessionStorage.getItem(key)
    if(temp){
        temp = JSON.parse(temp)
    }
    return temp;
}
