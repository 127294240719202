<template>
  <div class="tabs-home">
    <ul>
      <li
        v-for="(item, index) in tabs"
        :key="item.id"
        @click="changeTabs(item, index)"
      >
        <h3 :class="tabsActiveIndex === item.id ? 'selectedType' : ''">
          {{ item.title }}
        </h3>
        <img
          v-if="tabsActiveIndex === item.id"
          class="bottom-border"
          src="/images/appMainView/tabsBottom.png"
          alt=""
        />
      </li>
    </ul>
    <div class="new-hot" @click="handleSort">
      <span>{{ isSortNew ? '最新' : '最热' }}</span>
      <img class="hot-change" src="/images/appMainView/hotChange.png" alt="" />
    </div>
  </div>
</template>

<script>
import {
  getListRecommendCircle,
  getListJoinedCircleMessage
} from '@/api/circle'

export default {
  name: 'index',
  data () {
    return {
      tabs: [],
      tabsActiveIndex: 2,
      isSortNew: true,
      // 有我加入的圈子的固定tabs
      haveCircleFixedList: [
        {
          title: '我加入的',
          id: 1
        },
        {
          title: '广场',
          id: 2
        }
      ],
      // 没有我加入的圈子的固定tabs
      noCircleFixedList: [
        {
          title: '广场',
          id: 2
        }
      ]
    }
  },
  created () {
    this.getTabsList()
  },
  methods: {
    // 切换tabs
    changeTabs (item) {
      this.tabsActiveIndex = item.id
      this.$emit('changeTabs', item)
    },
    // 切换最新、最热
    handleSort () {
      this.isSortNew = !this.isSortNew
      this.$emit('handleSort', this.isSortNew)
    },
    // 获取推荐的圈子列表
    async getTabsList () {
      let tabsList = []
      try {
        /**
         * 获取我加入的圈子列表,
         * 数据则显示我加入的,
         * 否则只有广场
         */
        const list = await getListJoinedCircleMessage() || []
        if (list.length > 0) {
          tabsList = this.haveCircleFixedList
        } else {
          tabsList = this.noCircleFixedList
        }
      } catch (error) {
        tabsList = this.noCircleFixedList
      }

      const res = (await getListRecommendCircle()) || []

      tabsList = tabsList.concat(res)
      this.tabs = tabsList
      return this.tabs
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs-home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 20px 16px 10px 16px;

  ul {
    overflow-x: auto;
    display: flex;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 20px;

      .selectedType {
        color: #fa6400;
      }

      h3 {
        width: max-content;
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
      }

      .bottom-border {
        width: 23px;
        height: 5px;
        margin-top: 4px;
      }
    }
  }

  .new-hot {
    display: flex;
    flex-direction: row;
    align-items: center;
    //width: 55px;

    > span {
      width: max-content;
      font-size: 12px;
      text-align: RIGHT;
      color: #6d7278;
      line-height: 20px;
      letter-spacing: 0;
      padding: 0 2px 0 6px;
    }

    .hot-change {
      width: 10px;
      height: 10px;
      line-height: 20px;
      //margin-left: 4px;
      padding: 2px 0 2px 4px;
    }
  }
}
</style>
