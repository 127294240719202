<template>
  <div class="hot-article">
    <!-- todo   header-->
    <div class="hot-article-header">
      <div class="left-title">
        <img src="/images/appMainView/hotArticleLogo.png" alt="" />
        <h2>一周热帖</h2>
      </div>
      <div class="all-button" @click="goPage()">
        <span>全部</span>
        <img class="right-arrow" src="/images/appMainView/allRight.png" />
      </div>
    </div>
    <!--todo    列表-->
    <van-row class="hot-article-content">
      <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in newNavCardList" :key="index">
          <van-col span="12">
            <ul>
              <li
                v-for="(itemTow, indexTow) in item"
                :key="itemTow.id"
                @click="jumpDetail(itemTow.id)"
              >
                <div class="circle_item" v-if="indexTow < 3">
                  <span
                    class="index"
                    :class="{ first: (6 * index + indexTow) === 0, second: (6 * index + indexTow) === 1 }"
                  >{{ 6 * index + indexTow + 1 }}</span
                  >
                  <span class="title">{{ itemTow.title | subTitle }}</span>
                  <!-- <span>{{ index + 1 }}</span> -->
                  <img
                    v-if="itemTow.excellentCount > 20"
                    class="hot-img"
                    src="/images/appMainView/hot.png"
                    alt=""
                  />
                </div>
              </li>
            </ul>
          </van-col>
          <van-col span="12">
            <ul>
              <li
                v-for="(itemTow, indexTow) in item"
                :key="itemTow.id"
                @click="jumpDetail(itemTow.id)"
              >
                <div class="circle_item" v-if="indexTow >= 3 && indexTow < 6">
                  <span class="index">{{ 6 * index + indexTow + 1 }}</span>
                  <span class="title">{{ itemTow.title | subTitle }}</span>
                  <img
                    v-if="itemTow.excellentCount > 20"
                    class="hot-img"
                    src="/images/appMainView/hot.png"
                    alt=""
                  />
                </div>
              </li>
            </ul>
          </van-col>
        </van-swipe-item>
      </van-swipe>
    </van-row>
  </div>
</template>

<script>
import {articleExcellent} from '@/api/article'

export default {
  name: 'newIndex',
  data () {
    return {
      newNavCardList: [
        // {
        //   excellentCount: 26,
        //   id: "1518841416367382530",
        //   title: "评论数量"
        // }
      ]
    }
  },
  created () {
    this.getLists()
  },
  methods: {
    goPage () {
      this.$router.push({name: 'popular'})
    },
    async getLists () {
      let list = await articleExcellent({
        size: 50
      })
      // console.log(this.newNavCardList);
      // let list = this.newNavCardList;
      let groups = []
      let number = Math.ceil(list.length / 6)
      // debugger;
      for (let i = 0; i < number; i++) {
        groups.push(list.slice(6 * i, 6 * (i + 1)))
      }
      this.newNavCardList = groups
    },
    jumpDetail (id) {
      this.$router.push({name: 'topicDetail', query: {id}})
    }
  },
  filters: {
    subTitle (text) {
      if (text.length > 8) {
        return text.substr(0, 8) + '...'
      }
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .van-swipe__indicators {
    display: none;
  }
}

.hot-article {
  padding: 12px 12px 0;
  // background: linear-gradient(290deg, #fff9f6 0%, #fff7f5 100%);
  background-image: url('/images/appMainView/topPosts.png');
  background-size: 100% 100%;
  border-radius: 2px;
  //border: 1px dashed $mainColor;
}

.hot-article-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  /*border: 1px ;*/

  .left-title {
    display: flex;
    flex-direction: row;

    > img {
      width: 15px;
      height: 15px;
      margin-right: 6px;
    }

    h2 {
      margin: 0;
      font-size: 15px;
      font-family: PangMenZhengDao, PangMenZhengDao-Regular;
      color: #383433;
      line-height: 15px;
      letter-spacing: 0.02px;
    }
  }

  .all-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 46px;
    padding: 3px 5px 3px 6px;
    background: linear-gradient(90deg, #ff2951, #ff772e 100%);
    border-radius: 12px;

    > span {
      font-size: 10px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      color: #ffffff;
    }

    .right-arrow {
      margin-left: 4px;
      width: 11px;
      height: 11px;
    }
  }
}

.hot-article-content {
  /*display: flex;*/
  /*flex-direction: row;*/
  ul {
    li {
      .circle_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 12px;
        line-height: 17px;
      }

      .index {
        font-size: 13px;
        font-family: SF Pro Display, SF Pro Display-Medium;
        font-weight: bold;
        color: rgba(254, 88, 0, 0.4);
        //line-height: 13px;
      }

      .first {
        color: #fe5800;
      }

      .second {
        color: rgba(254, 88, 0, 0.7);
      }

      .title {
        margin-left: 10px;
        font-size: 12px;
        font-weight: bold;
        color: #383433;
        //line-height: 12px;
      }

      .hot-img {
        width: 10px;
        height: 10px;
        margin-left: 8px;
      }
    }
  }
}
</style>
